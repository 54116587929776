import clientApi from 'clientApi';
import { getCoupons, getRoPayments, getSettings, handleError } from 'redux/actions/dashboard/index';
import { getRewardsTiers } from 'redux/actions/dashboard/rewards';

// GET ROs by customer - endpoint cannot return other customers' ROs
export const getRos = (primaryCustomerId) => async (dispatch, getState) => {
  if (getState().dashboard.gettingRO) return;

  dispatch({ type: 'GET_ROS' });

  try {
    const { data } = await clientApi.getRos({
      sortField: 'dateCreated',
      sortDirection: 'DESC',
      primaryCustomerId,
      // TODO: if BE supports pagination, FE should implment infinite loading.
      pageLimit: 20,
    });

    dispatch({ type: 'RECEIVE_ROS', data });
    return data;
  } catch (err) {
    dispatch(handleError(err));
    return false;
  }
};
export const getRO = () => async (dispatch, getState) => {
  if (getState().dashboard.gettingRO) return;

  dispatch({ type: 'GET_RO' });

  try {
    const { data } = await clientApi.getRO();

    dispatch({ type: 'RECEIVE_RO', data });
    dispatch(getSettings());
    dispatch(getVideos(data.id));
    dispatch(getCoupons());
    dispatch(getRoPayments(data.id));

    if (data.primaryCustomer?.customerRewards) {
      dispatch(getRewardsTiers());
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};
export const startPollingRO = () => (dispatch) => {
  dispatch(getRO());

  console.log('NOT POLLING');
  // const roPollingInterval = setInterval(() => {
  //     dispatch(getRO());
  // }, 30000);
  // dispatch({ type: 'START_POLLING_RO', roPollingInterval });
};
export const stopPollingRO = () => {
  return (dispatch) => {
    dispatch({ type: 'STOP_POLLING_RO' });
  };
};

export const getVideos = (roId) => async (dispatch) => {
  dispatch({ type: 'GET_VIDEOS' });

  try {
    const { data } = await clientApi.getVideos(roId);

    dispatch({ type: 'RECEIVE_VIDEOS', data });
  } catch (err) {
    dispatch(handleError(err));
  }
};
