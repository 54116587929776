/* eslint-disable no-case-declarations */
import { reducers } from '@jmdigital/iservice3-utils';

const initialState = {
  conditions: [],
  coupons: [],
  error: null, // error object
  gettingRO: false, // can be used to indicate when the app is fetching latest data
  loading: false, // shows fullscreen loader and prevents user interaction
  paymentFormLoaded: false,
  payments: [],
  payment: null,
  repairOrder: null,
  reviewSites: [], // if this has length and a rating has been submitted, show this list
  ros: [], // used for showing a customer's RO history
  settings: null, // app settings - note that all values are strings, including numbers and booleans
  trims: [],
  videos: [],

  // Demo
  demo: null,

  // Rewards
  customerRewardsTier: {},
  rewardsTiers: [],

  // Status
  mpiAvailable: false,
  paymentAvailable: false,
  paymentPaid: false,
  quoteAvailable: false,
  quoteSubmitted: false,
  videoAvailable: false,
  viewedMpi: false,
  viewedUVEye: false,
  viewedQuote: false,
  viewedVideo: false,
  visitAuthorized: false,

  isMpiVisible: false,
  isQuoteVisible: false,
  visibleVideoId: '', // ID of video showing in modal

  appraisalRequested: false, // show the request appraisal card and allow request to be submitted
  financeRequested: false, // show the request finance card and allow request to be submitted

  // Waiver
  waiverVerbiage: null, // waiver text is stamped onto RO *at time of acknowledgement* or something like that
  waiverActivateAuthMessage: false, // false bypasses waiver card (via Settings)
};

const dashboardReducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'HANDLE_ERROR':
      return { ...state, loading: false, error: action.error };
    case 'DISMISS_ERROR':
      return { ...state, loading: false, error: null };
    case 'NOW_LOADING':
      return { ...state, loading: true };
    case 'END_LOADING':
      return { ...state, loading: false };
    case 'TOGGLE_MPI':
      return { ...state, isMpiVisible: !state.isMpiVisible };
    case 'TOGGLE_QUOTE':
      return {
        ...state,
        viewedQuote: !state.isQuoteVisible || state.viewedQuote,
        isQuoteVisible: !state.isQuoteVisible,
      };
    case 'TOGGLE_VIDEO':
      return {
        ...state,
        visibleVideoId: state.visibleVideoId ? '' : action.videoId,
      };
    case 'VIDEO_VIEWED': {
      const videos = state.videos.map((video) => {
        return video.id === action.video.id ? { ...action.video, status: 'Viewed' } : video;
      });
      return {
        ...state,
        videos,
      };
    }
    case 'TOGGLE_WAIVER':
      return { ...state };
    case 'TOGGLE_REVIEW':
      return { ...state };
    case 'GET_ROS':
      return { ...state, loading: true };
    case 'RECEIVE_ROS':
      return { ...state, loading: false, ros: data.results };
    case 'GET_RO':
      return {
        ...state,
        // Show loader overlay only if state hasn't been hydrated yet
        loading: !state.repairOrder,
        gettingRO: true,
      };
    case 'RECEIVE_RO':
      const viewedQuote = !!data.repairOrderQuote?.dateCustomerViewed;
      const quoteSubmitted = !!data.repairOrderQuote?.dateCompleted;
      const allowedQuoteStatuses = ['Sent', 'Viewed', 'Complete', 'Completed', 'Confirmed'];
      const quoteAvailable =
        !!data.repairOrderQuote?.id && allowedQuoteStatuses.includes(data.quoteStatus);
      const quoteComingSoonStatuses = [
        'Advisor In Progress',
        'Technician In Progress',
        'Parts In Progress',
      ];
      const quoteComingSoon = quoteComingSoonStatuses.includes(data.quoteStatus);
      if (data.quoteStatus === 'Sent' || data.quoteStatus === 'Viewed') {
        data.repairOrderQuote.repairOrderQuoteRecommendations.map((recommendation) => {
          if (recommendation.approved === false) {
            recommendation.approved = null;
          } else if (recommendation.approve === true) {
            recommendation.preApproved = true;
          }
        });
      }

      // Group previously approved recommendations with pre-approved to make them read-only
      if (data.repairOrderQuote?.repairOrderQuoteRecommendations) {
        data.repairOrderQuote.repairOrderQuoteRecommendations =
          data.repairOrderQuote.repairOrderQuoteRecommendations.map((rec) => {
            return {
              ...rec,
              previouslyApproved: !!rec.approved,
            };
          });
      }

      const viewedMpi = !!data.repairOrderMPI?.dateViewed;
      const allowedMpiStatuses = ['Sent', 'Viewed', 'Complete'];
      const mpiAvailable = !!data.repairOrderMPI?.id && allowedMpiStatuses.includes(data.mpiStatus);

      const viewedVideo = data.videoStatus === 'Viewed';
      const allowedVideoStatuses = ['Sent', 'Viewed'];
      const videoAvailable = allowedVideoStatuses.includes(data.videoStatus);

      const paidStatuses = ['Confirmed', 'Paid'];
      const paymentPaid = paidStatuses.includes(data.paymentStatus);
      const allowedPaymentStatuses = ['Confirmed', 'Paid', 'Sent', 'Viewed'];
      const paymentAvailable = allowedPaymentStatuses.includes(data.paymentStatus);
      const paymentComingSoon = data.paymentStatus === 'Created';

      if (data.primaryAdvisor?.phone) {
        data.primaryAdvisor.phone = data.primaryAdvisor.phone.replace(/\D/g, '') || '';
      }

      return {
        ...state,
        gettingRO: false,
        loading: false,

        repairOrder: data,
        waiverVerbiage: data.waiverVerbiage,

        // Status
        mpiAvailable,
        paymentAvailable,
        paymentComingSoon,
        paymentPaid,
        quoteAvailable,
        quoteComingSoon,
        quoteSubmitted,
        videoAvailable,
        viewedMpi,
        viewedQuote,
        viewedVideo,
        visitAuthorized: !!data.waiverSignature,
        appraisalRequested: false,
        financeRequested: false,
      };
    case 'RECEIVE_COUPONS':
      return { ...state, coupons: action.data };
    case 'RECEIVE_DEMO':
      return { ...state, demo: action.data };
    case 'RECEIVE_REWARDS_TIERS':
      // Have to sort and mutate to make these usable
      const rewardsTiersSorted = action.data.sort((tierA, tierB) => {
        return tierA.minimumSpend - tierB.minimumSpend;
      });
      const rewardsTiers = rewardsTiersSorted.map((tier, i) => {
        // Integers only, regardless of what's in DB
        tier.minimumSpend = Math.round(tier.minimumSpend);

        // Be careful with maximum spend - there are some edge cases
        // - No tiers with $1 minimum spend allowed
        // - No tiers with the same minimum spend
        // - Tiers with $1 increments will look weird in At a Glance
        tier.maximumSpend = Math.round(rewardsTiersSorted[i + 1]?.minimumSpend) - 1 || null;
        return tier;
      });

      // Never get rewards tiers before receiving RO and verifying customer has rewards
      // eslint-disable-next-line
      const { points } = state.repairOrder.primaryCustomer?.customerRewards;
      // Find customer's tier
      const customerRewardsTier = rewardsTiers.find((tier) => {
        return (
          points >= tier.minimumSpend && (points <= tier.maximumSpend || tier.maximumSpend === null)
        );
      });

      return { ...state, customerRewardsTier, rewardsTiers };
    case 'RECEIVE_SETTINGS':
      return reducers.receiveSettings(state, action, (settings) => {
        return {
          waiverActivateAuthMessage:
            settings?.waiverActivateAuthMessage === '1' ||
            settings?.waiverActivateAuthMessage === 'true',
        };
      });
    case 'RECEIVE_VIDEOS':
      const videos = Array.isArray(action.data) ? action.data.reverse() : [];

      return { ...state, loading: false, videos };
    case 'APPRAISAL_REQUESTED':
      return { ...state, loading: false, appraisalRequested: true };
    case 'FINANCE_REQUESTED':
      return { ...state, loading: false, financeRequested: true };
    case 'VISIT_AUTHORIZED':
      return { ...state, loading: false, visitAuthorized: true };
    case 'QUOTE_COMPLETED':
      return {
        ...state,
        loading: false,
        quoteSubmitted: true,
        isQuoteVisible: false,
      };
    case 'PAYMENT_FORM_ERRORED':
      return { ...state, paymentFormLoaded: false };
    case 'PAYMENT_FORM_LOADED':
      return { ...state, paymentFormLoaded: true };
    case 'GET_PAYMENT':
      return { ...state, payment: data, loading: true };
    case 'RECEIVE_PAYMENT':
      return { ...state, payment: data, loading: false };
    case 'MAKE_PAYMENT':
      return { ...state, loading: true };
    case 'PAYMENT_SUCCESS':
      return { ...state, payments: [], loading: false };
    case 'RECEIVE_PAYMENTS':
      return { ...state, payments: action.data, loading: false };
    case 'RECEIVE_CONDITIONS':
      return { ...state, conditions: data };
    case 'RECEIVE_TRIM':
      return { ...state, trims: data };
    case 'START_POLLING_RO':
      return { ...state, roPollingInterval: action.roPollingInterval };
    case 'STOP_POLLING_RO':
      const { roPollingInterval } = state;
      clearInterval(roPollingInterval);

      return { ...state, roPollingInterval: null };
    case 'RESET_DASHBOARD_STATE':
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default dashboardReducer;
